import './App.css';
import { useEffect, useState } from 'react';

import gif0 from "./0.gif";

import gif1 from "./1.gif";
import gif2 from "./2.gif";
import gif3 from "./3.gif";
import gif4 from "./4.gif";
import gif5 from "./5.gif";


function shuffleArr(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var rand = Math.floor(Math.random() * (i + 1));
    [array[i], array[rand]] = [array[rand], array[i]]
  }
  return array;
}

function vibe(t) {
  console.log({t});
  try {
    navigator.vibrate(t);
  } catch (error) {

  }
}

function App() {

  const [isQuiz, setIsQuiz] = useState(false);
  const [question, setQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [passwordCorrect, setPasswordCorrect] = useState(false);
  const [isIntro, setIsIntro] = useState(true);
  const [isResult, setIsResult] = useState(false);
  const [isFinalResult, setIsFinalResult] = useState(false);
  const [resultClock, setResultClock] = useState(0);

  useEffect(() => {
    [gif0, gif1, gif2, gif3].forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);



  const questions = [
    {
      "text": "Na svoju lásku myslím...",
      "options": ["vo dne, v noci", "hlavne v noci", "keď niečo potrebujem"],
      "pass": false
    },
    {
      "text": "Pre svoju lásku strácam...",
      "options": ["srdce a dušu", "rozum a sústredenosť", "čas a peniaze"],
      "pass": "túžba"
    },
    {
      "text": "Pre svoju lásku obetujem...",
      "options": ["život", "posledné úspory", "kariéru"],
      "pass": "bozk"
    },
    {
      "text": "Pri predstave romantiky vo dvojici myslím na...",
      "options": ["romantickú cestu okolo sveta", "príjemný večer pri sviečkach", "náročný plán, paniku a stres"],
      "pass": "neha"
    },
    {
      "text": "Aký filmový žáner vystihuje váš vzťah?",
      "options": ["romantický", "komediálny", "akčný"],
      "pass": "milovanie"
    },
    {
      "text": "Ako dávaš najavo lásku?",
      "options": ["skutkami", "slovami", "darmi"],
      "pass": "vášeň"
    },
    {
      "text": "Dnes som tu...",
      "options": ["dobrovoľne", "„nasilu“ :)"],
      "pass": "život"
    }
  ];

  return (
    <div className="App">
      {isIntro && <div className="App-content">
        <p style={{fontSize: "150%", marginBottom: 0}}>Vitajte!</p>
        <p>
        Vydajte sa s nami po ceste LÁSKY. Sledujete amorove šípy.
        </p>
        <a
          className="App-link"
          href="#"
          onClick={(e) => { e.preventDefault(); setTimeout(() => { setIsQuiz(true); setIsIntro(false); }, 500); }}
        >
          Prejsť na prvú otázku →
        </a>

      </div>}

      {(isQuiz && (questions[question].pass && !passwordCorrect)) && <div className="App-content">

        <p>Pre pokračovanie zadajte heslo, ktoré nájdete na ďalšej zastávke.</p>
        <input style={{
          borderRadius: 8,
          padding: 8,
          fontSize: 24,
          border: '1px solid #9f0932',
          textAlign: 'center'
        }} size={10} autoFocus={true} placeholder='Heslo...' autoComplete={'off'} type="text" onInput={(e) => { if (e.target.value.toLocaleLowerCase() === questions[question].pass.toLocaleLowerCase()) { setPasswordCorrect(true); } }} />

      </div>}

      {(isQuiz && !(questions[question].pass && !passwordCorrect)) && <div className="App-content">



        <p>
          {questions[question].text}
        </p>
        {shuffleArr(questions[question].options.map((answer, i) => {
          return (<button data-score={(4 - questions[question].options.length) * (questions[question].options.length - i - 1)} style={{
            borderRadius: 8,
            padding: 6,
            fontSize: 20,
            marginBottom: 5,
          }} key={answer} onClick={(e) => {
            const newScore = parseInt(score) + parseInt(e.target.dataset.score);
            setTimeout(() => {
              if ((questions.length - 1) > question) {
                setQuestion(question + 1);
                setPasswordCorrect(false);
              } else {
                setIsQuiz(false);
                setIsResult(true);

                setTimeout(() => {
                  let percentScore = (newScore / (questions.length * 2));
                  let level = 1;
                  if (percentScore > 0.2) {
                    level++;
                  }
                  if (percentScore > 0.4) {
                    level++;
                  }
                  if (percentScore > 0.6) {
                    level++;
                  }
                  if (percentScore > 0.9) {
                    level++;
                  }
                  const res = {
                    percent: 50 + percentScore * 50,
                    level
                  };
                  vibe([50, 50, 50, 50, 50, 50, 50, 50, 500, 500]);
                  setIsFinalResult(res);
                }, 12000);
              }
              setScore(newScore);
            }, 500);

          }}>{answer}</button>);
        }))}



      </div>}

      {isResult && <div className="App-content">
        <img style={{ height: "30vh", width: "90vw", borderRadius: 10, objectFit: 'contain' }} alt="" onLoad={() => { !isFinalResult && vibe(50); setTimeout(() => { setResultClock(resultClock + 1); }, 1000) }} src={isFinalResult ? [gif1, gif2, gif3, gif4, gif5][isFinalResult.level - 1] : [gif0, gif1, gif2, gif3, gif4, gif5][resultClock % 6]} />
        <div style={{ padding: 10, width: '90vw' }}>
          <div className='App-progress'>
            <div style={{textAlign: "center", fontSize: 22, width: (isFinalResult ? (isFinalResult.percent + "%") : ([10, 70, 30, 90, 20, 100][resultClock % 6] + "%")) }}>{isFinalResult && Math.ceil(isFinalResult.percent) + "%"}</div>
          </div>
        </div>
        <p>{isFinalResult ? ["Láska – neláska? Zamysli sa. Čím viac do lásky vložíš, tým viac dostaneš.", "Máš rád? Pouvažuj. Keď ľúbiš, nie je čo riešiť!", "Priateľská láska: Ľúbiš nežne, oddane, ale triezvo. Otvor sa láske naplno.", "Romantická láska: Srdce ti bije len pre lásku. Že ľúbiš, nie je pochýb.", "Láska úplná: Miluješ  bezhranične a naplno. Tvoja polovička má veľké šťastie."][isFinalResult.level - 1] : "Vyhodnocujem odpovede, chyťte sa za ruky!"}</p>

      </div>}
    </div>
  );
}

export default App;
